import { postRequest } from '@/libs/axios';

// 获取省市列表
export const getCityList = (params) => {
    return postRequest('/api/common/provinceAndSupportCity', params)
}

export const getCityList2 = (params) => {
    return postRequest('/api/common/provinceAndSupportCity', params)
}
