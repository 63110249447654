<template>
  <div class="addModel">
    <Form ref="formInline" :model="formData" :rules="ruleInline" :label-width="180">
      <FormItem label="门头照片：" prop="doorPic">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'doorPic')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.doorPic" />
            <img :src="formData.doorPic" v-if="formData.doorPic" />
          </div>
        </Upload>
        <!-- <p style="color:#c0c4cc">用户默认头像，后台添加用户以及用户登录的默认头像显示，尺寸(80*80)</p> -->
      </FormItem>
      <FormItem label="商户名称：" prop="storeName">
        <Input v-model="formData.storeName" style="width:400px"></Input>
      </FormItem>
      <FormItem label="商户类型：" prop="storeType">
        <Select v-model="formData.storeType" style="width:400px" clearable>
          <Option
            v-for="item in dataList"
            :value="item.dicCode"
            :key="item.dicCode"
          >{{ item.dicName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="选择省：" prop="proId" style="width:400px">
        <Select v-model="formData.proId" style="width:400px" clearable @on-change="selectPro">
          <Option v-for="item in cityList" :value="item.proId" :key="item.proId">{{ item.proName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="选择市：" prop="cityId" style="width:400px">
        <Select v-model="formData.cityId" style="width:400px" clearable>
          <Option
            v-for="item in cityChildList"
            :value="item.cityId"
            :key="item.cityId"
          >{{ item.cityName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="商户地址：" prop="storeAddress">
        <div id="mapContainer" style="height:180px;width:400px;margin-bottom:10px"></div>
        <!-- <div>
          <p>点击的标记点位：{{ selectedMarker }}</p>
          <p>回显的地址：{{ selectedAddress }}</p>
        </div>-->
        <!-- <baidu-map
          :ak="appkey"
          @click="handleMapClick"
          :center="mapCenter"
          :zoom="zoomLevel"
          scroll-wheel-zoom
          enableHighAccuracy
          style="height: 180px;width:400px;margin-bottom:10px"
        >
          <bm-control></bm-control>
          <bm-marker v-if="selectedPoint" :position="selectedPoint" :options="{title: '选中位置'}"></bm-marker>
        </baidu-map>-->
        <input
          type="text"
          v-model="selectedAddress"
          style="width:400px"
          id="autoInput"
          class="ivu-input ivu-input-default"
        />
        <!-- <Input v-model="selectedAddress" id="autoInput2"  placeholder="地址" style="width:400px"></Input> -->
      </FormItem>
      <FormItem label="推荐用户：">
        <div class="ivu-input" style="width:400px" @click="modelShow=true">{{formData.parentName}}</div>
      </FormItem>
      <FormItem label="营业执照类型：" prop="charterType">
        <Select v-model="formData.charterType" style="width:400px" clearable>
          <Option
            v-for="item in charterTypeList"
            :value="item.dicCode"
            :key="item.dicCode"
          >{{ item.dicName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="营业执照照片：" prop="charterPic">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'charterPic')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.charterPic" />
            <img :src="formData.charterPic" v-if="formData.charterPic" />
          </div>
        </Upload>
      </FormItem>
      <FormItem label="统一社会信用代码：" prop="socialCreditCode">
        <Input v-model="formData.socialCreditCode" style="width:400px"></Input>
      </FormItem>
      <FormItem label="支付宝对公账户：" prop="alipayPublicAccount">
        <Input v-model="formData.alipayPublicAccount" style="width:400px"></Input>
      </FormItem>
      <FormItem label="支付宝对公账户名称：" prop="alipayPublicAccountName">
        <Input v-model="formData.alipayPublicAccountName" style="width:400px"></Input>
      </FormItem>
      <FormItem label="法人名称：" prop="corpName">
        <Input v-model="formData.corpName" style="width:400px"></Input>
      </FormItem>
      <FormItem label="法人身份证：" prop="corpIdcard">
        <Input v-model="formData.corpIdcard" style="width:400px"></Input>
      </FormItem>
      <FormItem label="法人身份证有效期：" prop="corpIdcardValidDate">
        <Input v-model="formData.corpIdcardValidDate" style="width:400px"></Input>
      </FormItem>
      <FormItem label="法人手机号：" prop="corpPhone">
        <Input v-model="formData.corpPhone" style="width:400px"></Input>
      </FormItem>
      <FormItem label="法人身份证正面照片：" prop="corpIdcardFrontPic">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'corpIdcardFrontPic')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.corpIdcardFrontPic" />
            <img :src="formData.corpIdcardFrontPic" v-if="formData.corpIdcardFrontPic" />
          </div>
        </Upload>
      </FormItem>
      <FormItem label="法人身份证背面照片：" prop="corpIdcardBackPic">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'corpIdcardBackPic')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.corpIdcardBackPic" />
            <img :src="formData.corpIdcardBackPic" v-if="formData.corpIdcardBackPic" />
          </div>
        </Upload>
      </FormItem>
      <FormItem label="食品卫生许可证照片：" prop="foodHygieneLicencePic">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'foodHygieneLicencePic')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.foodHygieneLicencePic" />
            <img :src="formData.foodHygieneLicencePic" v-if="formData.foodHygieneLicencePic" />
          </div>
        </Upload>
      </FormItem>
      <FormItem label="商户电话：" prop="storeTel">
        <Input v-model="formData.storeTel" style="width:400px"></Input>
      </FormItem>
      <!-- <FormItem label="营业时间：" prop="storeOpenHours">
        <Input v-model="formData.storeOpenHours" style="width:400px"></Input>
      </FormItem>-->
      <FormItem label="营业时间：" prop="storePeriodList">
        <Table border :columns="columns" :data="formData.storePeriodList">
          <template #storeDate="{ row,index }">
            <Input v-model="formData.storePeriodList[index].storeDate"></Input>
          </template>
          <template slot-scope="{ row,index }" slot="date">
            <!-- <TimePicker
              :transfer="true"
              type="timerange"
              placement="bottom-end"
              placeholder="Select time"
              v-model="formData.storePeriodList[index].dateArr"
            ></TimePicker>-->
            {{storePeriodList}}
            <TimePicker
              style="width:100px;margin-right:20px"
              class="fl"
              :transfer="true"
              type="time"
              placement="bottom-end"
              placeholder="Select time"
              v-model="formData.storePeriodList[index].startTime"
            ></TimePicker>
            <TimePicker
              style="width:100px"
              class="fl"
              :transfer="true"
              type="time"
              placement="bottom-end"
              placeholder="Select time"
              v-model="formData.storePeriodList[index].endTime"
            ></TimePicker>
          </template>
          <template #action="{ index }">
            <a @click="delTime(index)">删除</a>
          </template>
        </Table>
        <a @click="addTime">添加营业时间</a>
      </FormItem>
      <FormItem label="商户标签：" prop="storeTag">
        <Input type="textarea" v-model="formData.storeTag" style="width:400px"></Input>
      </FormItem>
      <FormItem label="商户照片：" prop="shopAttachList">
        <Upload
          style="display:inline-block"
          ref="upload"
          action="/api/upload/resources"
          multiple
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :default-file-list="defaultList"
          :on-success="(res)=>{handleSuccess(res,'shopAttachList')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" />
          </div>
        </Upload>
        <div
          v-show="formData.shopAttachList.length"
          class="demo-upload-list door-photograph"
          v-for="(item,key) in formData.shopAttachList"
          :key="key"
        >
          <template v-if="item.hostPic">
            <img :src="item.hostPic" />
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" @click.stop.prevent.native="handleView(item.hostPic)"></Icon>
              <Icon type="ios-trash-outline" @click.stop.prevent.native="handleRemove(key)"></Icon>
            </div>
          </template>
        </div>
      </FormItem>
      <!-- <FormItem label="分润比列：" prop="storeSpreadRate">
        <Input type="number" v-model="formData.storeSpreadRate" style="width:400px">
         <span slot="append">%</span>
        </Input>
      </FormItem>-->
      <FormItem label="是否开通开团功能：" prop="groupFlag">
        <i-switch v-model="formData.groupFlag" />
      </FormItem>
      <FormItem label="商户详情：">
        <vue-editor
          ref="editor"
          v-model="formData.storeDetail"
          :useCustomImageHandler="true"
          @image-added="uploadImage"
        ></vue-editor>
      </FormItem>
    </Form>
    <Modal v-model="modelShow" title="选择推荐用户" width="800">
      <userSelect ref="userSelect" v-if="modelShow" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Modal>
    <Modal title="预览" v-model="visible">
      <div style="max-height:500px;overflow:auto;">
        <img :src="srcUrl" v-if="visible" style="width: 100%" />
      </div>
    </Modal>
  </div>
</template>

<script>
import userSelect from './userSelect.vue'

// import { BmMarker, BmControl } from 'vue-baidu-map'
import { getSessionStore } from '@/libs/storage';

import { VueEditor } from 'vue2-editor';
import axios from 'axios';
// import AMapLoader from 'vue-amap';

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
    titleName: {
      type: String,
      default: ''
    },
    dictionaryEntry: {
      type: Array,
      default: () => []
    },
    charterTypeList: {
      type: Array,
      default: () => []
    },
    cityList: {
      type: Array,
      default: () => []
    },
  },
  components: {
    userSelect,
    VueEditor
    // BmControl,
    // BmMarker
  },
  data() {
    return {
      dataObj: {
        userId: this.$store.getters.userInfo.id
      },
      headersData: {
        'X-Token': getSessionStore('accessToken')
      },
      columns: [
        {
          title: '营业日',
          key: 'storeDate',
          slot: 'storeDate'
        },
        {
          title: '营业时段',
          slot: 'date',
        },
        {
          title: '操作',
          slot: 'action',
          width: 80,
        },
      ],
      formData: {
        storeName: '',
        storeType: '',
        storeAddress: '',
        proId: '',
        cityId: '',
        parentId: '',
        parentName: '',
        storeTel: '',
        // storeOpenHours: '',
        storePeriodList: [
          {
            "endTime": "02:00:00",
            "seq": 0,
            "startTime": "10:00:00",
            "storeDate": "周一到周五",
            dateArr: []
          }
        ],
        shopAttachList: [],
        storeTag: '',
        doorPic: '',
        latitude: '',
        longitude: '',
        storeDetail: '',
        alipayPublicAccount: '',
        alipayPublicAccountName: '',
        charterPic: '',
        corpIdcard: '',
        corpIdcardValidDate: '',
        socialCreditCode: '',
        corpIdcardBackPic: '',
        corpIdcardFrontPic: '',
        corpName: '',
        corpPhone: '',
        foodHygieneLicencePic: '',
        storeSpreadRate: '',
        groupFlag: false
      },
      ruleInline: {
        doorPic: [
          { required: true, message: '请上传门头照！', trigger: 'blur' }
        ],
        storeName: [
          { required: true, message: '请填写商户名称！', trigger: 'blur' }
        ],
        storeType: [
          { required: true, message: '请选择商户类型！', trigger: 'blur' }
        ],
        storeAddress: [
          { required: true, message: '请选择商户地址！', trigger: 'blur' }
        ],
        proId: [
          { required: true, message: '请选择省！', trigger: 'blur' }
        ],
        cityId: [
          { required: true, message: '请选择市！', trigger: 'blur' }
        ],
        charterType: [
          { required: true, message: '请选择营业执照类型！', trigger: 'blur' }
        ],
        charterPic: [
          { required: true, message: '请上传营业执照！', trigger: 'blur' }
        ],
        alipayPublicAccount: [
          { required: true, message: '请填写支付宝对公账户！', trigger: 'blur' }
        ],
        alipayPublicAccountName: [
          { required: true, message: '请填写支付宝对公账户名称！', trigger: 'blur' }
        ],
        corpName: [
          { required: true, message: '请填写法人名称！', trigger: 'blur' }
        ],
        corpIdcard: [
          { required: true, message: '请填写法人身份证！', trigger: 'blur' }
        ],
        corpIdcardValidDate: [
          { required: true, message: '请填写法人身份证有效期！', trigger: 'blur' }
        ],
        socialCreditCode: [
          { required: true, message: '请填写统一社会信用代码！', trigger: 'blur' }
        ],
        corpPhone: [
          { required: true, message: '请填写法人手机号！', trigger: 'blur' }
        ],
        corpIdcardFrontPic: [
          { required: true, message: '请上传法人身份证正面照！', trigger: 'blur' }
        ],
        corpIdcardBackPic: [
          { required: true, message: '请上传法人身份证背面照！', trigger: 'blur' }
        ],
        foodHygieneLicencePic: [
          { required: true, message: '请上传食品卫生许可证照！', trigger: 'blur' }
        ],
        storeTel: [
          { required: true, message: '请填写商户电话！', trigger: 'blur' }
        ],
        storeOpenHours: [
          { required: true, message: '请填写营业时间！', trigger: 'blur' }
        ],
        storePeriodList: [
          { required: true, type: 'array', message: '请填写营业时间！', trigger: 'blur' }
        ],
        shopAttachList: [
          { required: true, type: 'array', message: '请上传商户照片！', trigger: 'blur' }
        ],
        storeTag: [
          { required: true, message: '请填写商户标签！', trigger: 'blur' }
        ],
        storeSpreadRate: [
          { required: true, message: '请填写分润比例！', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const rate = parseFloat(value); // 转换为浮点数
              if (isNaN(rate)) {
                callback(new Error('请输入有效的数字！'));
              } else if (rate <= 0 || rate > 100) {
                callback(new Error('分润比例必须大于0且不超过100！'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      },
      cityChildList: [],
      dataList: [
        // {
        //   value: '1',
        //   label: 'KTV'
        // },
        // {
        //   value: '2',
        //   label: '酒吧'
        // },
      ],
      map: null,
      markers: [],
      selectedMarker: null,
      center: [120.1551, 30.2741],
      zoomLevel: 12,
      // mapCenter: { lng: 120.1551, lat: 30.2741 },
      // anchor: 'BMAP_ANCHOR_TOP_RIGHT',
      // appkey: 'QPdGhi5ViUtYO8sFE6fRBK720YRE5EGG',
      // selectedPoint: null, // 用于存储选择的坐标点
      selectedAddress: '',  // 用于回显的地址输入框的值
      editorOptions: {
        // 配置选项
      },
      modelShow: false,
      imgName: '',
      srcUrl: '',
      visible: false,
      defaultList: [],
      shopAttachList: []
    }
  },
  created() {
    this.dataList = this.dictionaryEntry
    if (this.titleName != '新增') {
      this.selectPro(this.currentData.proId)
      this.formData = this.currentData
      this.currentData.storePeriodList.forEach(item => {
        item.dateArr = [
          item.startTime,
          item.endTime
        ]
      })
      this.formData.proId = String(this.currentData.proId)
      this.formData.cityId = String(this.currentData.cityId)
      this.selectedAddress = this.currentData.storeAddress
      this.shopAttachList = this.formData.shopAttachList || []
      if (this.currentData.latitude && this.currentData.longitude) {
        this.center = [Number(this.currentData.longitude), Number(this.currentData.latitude)]
      }
    } else {
      let formDataJson = sessionStorage.getItem('formData');
      if (formDataJson) {
        let formData = JSON.parse(formDataJson)
        if (formData.proId) {
          this.selectPro(formData.proId)
        }
        if (formData.storePeriodList.length) {
          console.log(formData.storePeriodList, 123)
          formData.storePeriodList.forEach(item => {
            item.dateArr = [
              item.startTime,
              item.endTime
            ]
          })
        }
        this.formData = formData
        this.selectedAddress = this.formData.storeAddress
      }
    }
  },
  watch: {
    selectedAddress(n) {
      console.log(n, 123)
      this.formData.storeAddress = n
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.map = new window.AMap.Map('mapContainer', {
        center: this.center, // 地图中心点坐标
        zoom: this.zoomLevel // 地图缩放级别
      });
      // 创建自动完成的输入框
      const autoInput = document.getElementById('autoInput');
      const autoComplete = new window.AMap.Autocomplete({
        input: autoInput// 输入框的 ID 或 DOM 元素
      });
      autoComplete.on("select", (e) => {
        const location = e.poi.location; // 获取选中的地址坐标
        console.log(e)
        if (!location) {
          return
        }
        this.selectedAddress = e.poi.name
        let arr = [String(location.lng), String(location.lat)]
        this.formData.latitude = String(location.lat)
        this.formData.longitude = String(location.lng)
        this.clearMarkers()
        const marker = new window.AMap.Marker({
          position: arr,
          map: this.map
        });
        this.markers.push(marker);
        this.selectedMarker = marker.getPosition().toString();
        var position = marker.getPosition();
        // 将地图中心点设置为标记位置
        this.map.setCenter(position);
      });

      if (this.titleName != '新增' || (this.formData.longitude && this.formData.latitude)) {
        console.log([this.formData.longitude, this.formData.latitude])
        const marker = new window.AMap.Marker({
          position: [this.formData.longitude, this.formData.latitude],
          map: this.map
        });

        this.markers.push(marker);
        this.selectedMarker = marker.getPosition().toString();
      }
      this.map.on('complete', () => {
        this.map.on('click', (e) => {
          this.clearMarkers()
          const marker = new window.AMap.Marker({
            position: e.lnglat,
            map: this.map
          });

          this.markers.push(marker);
          this.selectedMarker = marker.getPosition().toString();

          // 逆地理编码获取地址
          const geocoder = new window.AMap.Geocoder();
          let point = [e.lnglat.lng, e.lnglat.lat]
          console.log(point)
          this.formData.latitude = e.lnglat.lat
          this.formData.longitude = e.lnglat.lng
          geocoder.getAddress(point, (status, result) => {
            console.log(status, result, '2132')
            if (status === 'complete' && result.regeocode) {
              this.selectedAddress = result.regeocode.formattedAddress;
              this.formData.storeAddress = result.regeocode.formattedAddress;
            }
          });
        });
      });
    },
    selectPro(id) {
      this.formData.cityId = ''
      if (id) {
        this.cityList.forEach(item => {
          if (id == item.proId) {
            this.cityChildList = item.cityList || []
          }
        })

      } else {
        this.cityChildList = []
      }
    },
    addTime() {
      this.formData.storePeriodList.push({
        "endTime": "",
        "startTime": "",
        "storeDate": "",
        dateArr: []
      })
    },
    delTime(index) {
      this.formData.storePeriodList.splice(index, 1)
    },
    addMarker() {
      const marker = new window.AMap.Marker({
        position: [104.07, 30.67],
        map: this.map
      });

      this.markers.push(marker);
      this.selectedMarker = marker.getPosition().toString();
    },
    clearMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }

      this.markers = [];
      this.selectedMarker = null;
      this.address = '';
    },
    handleView(url) {
      this.srcUrl = url
      // this.imgName = name;
      this.visible = true;
    },
    handleRemove(key) {
      this.shopAttachList.splice(key, 1);
      this.$set(this.formData, 'shopAttachList', this.shopAttachList);
    },
    handleUpload(file) {
      console.log(file)
    },
    handleSuccess(res, type) {
      if (type == 'shopAttachList') {
        console.log(this.shopAttachList)
        this.shopAttachList.push({ hostPic: res.result })
        this.$set(this.formData, 'shopAttachList', this.shopAttachList);
        return
      }
      this.formData[type] = res.result;
    },
    async uploadImage(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', this.$store.getters.userInfo.id);

      const response = await axios.post('/api/upload/resources', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Token': getSessionStore('accessToken')
        }
      });

      // 在编辑器中插入图片的回显
      const imageUrl = response.result;
      this.formData.storeDetail += `<img src="${imageUrl}" alt="Image" />`
      this.$refs.editor.quill.root.innerHTML = this.formData.storeDetail;
    },
    save() {
      if (this.$refs.userSelect.currentData.id) {
        this.formData.parentId = this.$refs.userSelect.currentData.id
        this.formData.parentName = this.$refs.userSelect.currentData.nickname
        this.modelShow = false
      } else {
        this.$Message.warning('请选择推荐用户！')
      }
    },
    // handleMapClick(event) {
    //   this.selectedPoint = event.point; // 获取点击的地图坐标点
    //   this.getAddressByPoint(this.selectedPoint); // 调用方法获取坐标点对应的地址
    // },
    // getAddressByPoint(point) {
    //   console.log(point)
    //   const geoc = new window.BMap.Geocoder();
    //   geoc.getLocation(point, (rs) => {
    //     if (rs && rs.address) {
    //       const address = rs.address;
    //       this.selectedAddress = address
    //       console.log(address);
    //     } else {
    //       console.error('无法获取地址信息');
    //     }
    //   });
    // },
    // handleZoomEnd(zoom) {
    //   this.zoomLevel = zoom
    //   // this.zoomLevel = newZoomLevel;
    // }
  }
}
</script>

<style lang='scss'>
.addModel {
  height: 480px;
  overflow: auto;
  .door-photograph {
    display: inline-block;
    margin-left: 8px;
    width: 180px;
    height: 120px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    line-height: 120px;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .ivu-upload-list {
    display: none;
  }
  .demo-upload-list {
    display: inline-block;
    width: 180px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
  }
  .demo-upload-list img {
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    margin: 0 2px;
  }
}
</style>