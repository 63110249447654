<template>
  <div class="appUserSelect">
    <div class="table-area">
      <Table :columns="columns" highlight-row @on-current-change="onCurrentChange" :data="dataList" size="small" :height="tableHeight"></Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getLaikeList } from '@/api/storeUserManagement.js'

export default {
  data() {
    return {
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '用户id',
          key: 'id'
        },
        {
          title: '支付宝对公账号',
          key: 'alipayAccount'
        },
         {
          title: '支付宝对公账号名称',
          key: 'alipayAccountName'
        },
         {
          title: '手机号',
          key: 'mobile'
        },
        {
          title: '商户id',
          key: 'storeId'
        },
        {
          title: '	创建时间',
          key: 'createTime'
        }
      ],
      dataList: [
      ],
      currentData: {},
      tableHeight: 300,
    }
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      let params = { size: this.size, pageIndex: this.current }
      getLaikeList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    onCurrentChange(row){
        this.currentData = row
    }
  }
}
</script>

<style>
</style>