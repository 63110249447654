import { postRequest } from '@/libs/axios';


// 系统配置-七牛云配置
export const getQNYconfig = (params) => {
    return postRequest('/api/config/qiniuyun/config', params)
}

// 系统配置-编辑保存七牛云配置
export const editQNYconfig = (params) => {
    return postRequest('/api/config/edit/qiniuyun/config', params)
}

// 系统配置-短信配置
export const getDXPZconfig = (params) => {
    return postRequest('/api/config/sms/config', params)
}
// 系统配置-编辑保存短信配置
export const editDXPZconfig = (params) => {
    return postRequest('/api/config/edit/sms/config', params)
}

// 营销管理-获取佣金配置
export const findBrokerageConfig = (params) => {
    return postRequest('/api/config/brokerage/config', params)
}
// 营销管理-编辑保存佣金配置
export const editBrokerageConfig = (params) => {
    return postRequest('/api/config/edit/brokerage/config', params)
}
// 营销管理--获取分销配置
export const findDistributionConfig = (params) => {
    return postRequest('/api/config/distribution/config', params)
}
// 营销管理-编辑保存佣金配置
export const editDistributionConfig = (params) => {
    return postRequest('/api/config/edit/distribution/config', params)
}
//字典项
export const getDictionaryEntry = (params) => {
    return postRequest('/api/sys/dictionary/list', params)
}
