import { postRequest } from '@/libs/axios';

// 商家app用户列表
export const getLaikeList = (params) => {
    return postRequest('/api/laike/user/list', params)
}

// 商家app用户注册
export const registerUser = (params) => {
    return postRequest('/api/laike/user/register', params)
}
