import { postRequest } from '@/libs/axios';


// 用户管理分页列表
export const findMemberPageList = (params) => {
    return postRequest('/api/member/page', params)
}

// 用户管理-编辑
export const editMember = (params) => {
    return postRequest('/api/member/edit', params)
}

// 用户管理-详情
export const detalMember = (params) => {
    return postRequest('/api/member/detail', params)
}

// 用户管理-删除
export const deleteMember = (params) => {
    return postRequest('/api/member/delete', params)
}

//用户管理-会员-圈豆账户基础详情
export const getIntegralInfo = (params) => {
    return postRequest('/api/member/integral/baseInfo', params)
}

//用户管理-会员-圈豆账户列表
export const getIntegralList = (params) => {
    return postRequest('/api/colonel/integral/list', params)
}

//用户管理-会员-参与活动基本详情
export const getParticipateActivitiesInfo = (params) => {
    return postRequest('/api/member/order/baseInfo', params)
}

//用户管理-会员-参与活动基本详情
export const getParticipateActivitiesList = (params) => {
    return postRequest('/api/member/order/list ', params)
}
 
 




//配置相关-------------------------------------
//用户管理-获取用户配置
export const getUserConfig = (params) => {
    return postRequest('/api/member/config', params)
}

//用户管理-编辑保存用户配置
export const editUserConfig = (params) => {
    return postRequest('/api/member/edit/config', params)
}

//用户管理-获取圈豆配置
export const getCouponConfig = (params) => {
    return postRequest('/api/member/coupon/config', params)
}

//用户管理-编辑保存圈豆配置
export const editCouponConfig = (params) => {
    return postRequest('/api/member/edit/coupon/config', params)
}

//用户管理-获取团配置
export const getRegimentConfig = (params) => {
    return postRequest('/api/member/regiment/config', params)
}

//用户管理-编辑保存团配置
export const editRegimentConfig = (params) => {
    return postRequest('/api/member/edit/regiment/config', params)
}

