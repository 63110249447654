<template>
  <div class="businessSelect">
    <div class="search-area">
      <Input
        v-model="searchText"
        placeholder="请输入id搜索"
        clearable
        style="width:240px"
      />
      <Input
        v-model="mobile"
        placeholder="请输入手机号搜索"
        clearable
        style="width:240px;margin:0 10px"
      />
      <Button type="primary" @click="search">搜索</Button>
    </div>
    <div class="table-area">
      <Table
        :columns="columns"
        highlight-row
        @on-current-change="onCurrentChange"
        :data="dataList"
        size="small"
        :height="tableHeight"
      >
        <template slot-scope="{ row }" slot="sex">
          <div v-if="row.sex == 1">男</div>
          <div v-if="row.sex == 2">女</div>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        :page-size="size"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { findMemberPageList } from '@/api/memberCenter.js'

export default {
  data() {
    return {
      searchText: '',
      mobile: '',
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: 'id',
          key: 'id'
        },
        {
          title: '姓名',
          key: 'nickname'
        },
        {
          title: '性别',
          key: 'sex',
          slot: 'sex',
        },
        {
          title: '身份证号',
          key: 'idCard'
        },
        {
          title: '手机号',
          key: 'mobile',
        },
      ],
      dataList: [],
      currentData: {},
      tableHeight: 300
    }
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { size: this.size, pageIndex: this.current, userType: 2 }
      if (this.searchText) {
        params.id = this.searchText
      }
      if(this.mobile){
        params.mobile = this.mobile
      }
      findMemberPageList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    onCurrentChange(row) {
      this.currentData = row
    }
  }
}
</script>

<style>
</style>