<!-- 商家管理页面 -->
<template>
  <div class="businessManagement">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">商家搜索</div>
          <Input class="fl" v-model="keywords" placeholder="商家名称/手机号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">商家类别</div>
          <Select v-model="storeType" style="width:200px" clearable>
            <Option
              v-for="item in dictionaryEntry"
              :value="item.dicCode"
              :key="item.dicCode"
            >{{ item.dicName }}</Option>
          </Select>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">选择省</div>
          <Select v-model="proId" style="width:200px" clearable @on-change="selectPro">
            <Option
              v-for="item in cityList"
              :value="item.proId"
              :key="item.proId"
            >{{ item.proName }}</Option>
          </Select>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">选择市</div>
          <Select v-model="cityId" style="width:200px" clearable>
            <Option
              v-for="item in cityChildList"
              :value="item.cityId"
              :key="item.cityId"
            >{{ item.cityName }}</Option>
          </Select>
        </div>
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <Button type="primary" @click="add">新增商家</Button>
    <!-- <div class="top-area clr">
      <Button type="primary" @click="add">新增商家</Button>
      <Input
        v-model="seachText"
        style="width:240px"
        class="fr"
        search
        placeholder="请输入..."
        @on-search="search"
      />
    </div>-->
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="doorPic">
          <img style="width:auto;height:40px;" :src="row.doorPic" alt />
        </template>
        <template slot-scope="{ row }" slot="storeType">{{getStoreTypeName(row.storeType)}}</template>
        <template slot-scope="{ row }" slot="bindAppUser">
          <a @click="bind(row)">商家版app账号绑定</a>
        </template>
        <template slot-scope="{ row }" slot="referrer">
          <div v-if="!row.parentId">
            <a @click="setReferrer(row)">设置推荐人</a>
          </div>
          <div v-else>
            <a @click="delReferrer(row)">删除推荐人</a>
          </div>
        </template>
        <template slot-scope="{ row }" slot="parentName">
          <div>{{row.parentName}}</div>
        </template>

        <template slot-scope="{ row }" slot="storeSpreadRate">
          <div v-if="row.storeSpreadRate">{{row.storeSpreadRate}}%</div>
        </template>
        <template slot-scope="{ row }" slot="action">
          <a @click="checkCombo(row)" style="margin-right:10px">套餐</a>
          <a @click="editDetial(row)" style="margin-right:10px">编辑</a>
          <a @click="del(row)" style="margin-right:0px">删除</a>
          <!-- <a @click="generateQRCode(row.qrCode)" :href="row.qrCode" target="_blank">二维码下载</a> -->
          <a @click="generateQRCode(row)">二维码下载</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        :page-size="size"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <Modal v-model="addModelShow" :title="titleName" width="900">
      <addModel
        ref="addModel"
        :currentData="currentData"
        :titleName="titleName"
        :dictionaryEntry="dictionaryEntry"
        :charterTypeList="charterTypeList"
        :cityList="cityList"
        v-if="addModelShow"
      />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="addModelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
        <Button
          style="margin-left: 8px"
          type="primary"
          @click="saveDraft"
          v-if="titleName== '新增'"
        >保存草稿</Button>
      </div>
    </Modal>
    <div class="packageManagement" v-if="comboManagementShow">
      <comboManagement
        @back="comboManagementShow = false"
        :storeId="currentData.id"
        :storeMsg="currentData"
        v-if="comboManagementShow"
      />
    </div>
    <Modal v-model="modelShow" title="选择推荐用户" width="800">
      <userSelect ref="userSelect" v-if="modelShow" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="saveFerrer">确定</Button>
      </div>
    </Modal>
    <Modal v-model="appUsermodelShow" title="绑定商家app用户" width="800">
      <appUserSelect ref="appUserSelect" v-if="appUsermodelShow" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="appUsermodelShow = false">取消</Button>
        <Button type="primary" @click="bindAppUser">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import QRCode from 'qrcode-generator';
import addModel from './components/addModel.vue'
import comboManagement from '../comboManagement/index.vue'
import userSelect from './components/userSelect.vue'
import appUserSelect from './components/appUserSelect.vue'

import { getStoreList, delStore, addStore, getDetial, setStoreParent, delStoreParent, bindStoreUser } from '@/api/businessManagement.js'
import { getDictionaryEntry } from '@/api/systemConfiguration.js'
import { getCityList } from '@/api/common.js'


export default {
  components: {
    addModel,
    comboManagement,
    userSelect,
    appUserSelect
  },
  data() {
    return {
      qrCodeDataUrl: null,
      keywords: '',
      storeType: '',
      proId: '',
      cityId: '',
      cityChildList: [],
      tableHeight: window.innerHeight - 330,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        // {
        //   title: 'id',
        //   key: 'id'
        // },
        {
          title: '商家名称',
          key: 'storeName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '商户类型',
          key: 'storeType',
          slot: 'storeType',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '商户地址',
          key: 'storeAddress',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '商户门头照',
          key: 'doorPic',
          slot: 'doorPic'
        },
        {
          title: '商户电话',
          key: 'storeTel'
        },
        // {
        //   title: '商户用户id',
        //   key: 'laikeUserId'
        // },
        {
          title: '商家版app账号',
          key: 'laikeUserMobile'
        },
        {
          title: '商家版app账号绑定',
          width: 150,
          slot: 'bindAppUser',
        },
        // {
        //   title: '分润比例',
        //   width: 90,
        //   slot: 'storeSpreadRate',
        // },
        {
          title: '推荐人设置',
          width: 100,
          slot: 'referrer',
        },
        {
          title: '推荐人',
          width: 100,
          slot: 'parentName',
        },
        {
          title: '操作',
          width: 130,
          slot: 'action',
        },
      ],
      dataList: [],
      dictionaryEntry: [],
      charterTypeList: [],
      cityList: [],
      currentData: {},
      addModelShow: false,
      titleName: '新增',
      comboManagementShow: false,
      modelShow: false,
      appUsermodelShow: false
    };
  },
  created() {
    this.getDictionaryEntry()
    this.getCharterTypeList()
    this.getCityList()
  },
  methods: {
    getCityList() {
      getCityList({}).then(res => {
        if (res.success) {
          this.cityList = res.result
          // this.traverseTree(this.cityList)
        }
      })
    },
    traverseTree(arr) {
      // 遍历当前节点的子节点
      arr.forEach(node => {
        node.label = node.proName || node.cityName
        node.value = node.proId || node.cityId
        // node.children = node.cityList
        if (node.cityList && node.cityList.length > 0) {
          node.children = this.traverseTree(node.cityList); // 递归调用
        }
      })
      return arr
    },
    reset() {
      this.keywords = ''
      this.storeType = ''
      this.proId = ''
      this.cityId = ''
      this.search()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    getDictionaryEntry() {
      getDictionaryEntry({ dicType: 'regiment_type' }).then(res => {
        if (res.success) {
          this.dictionaryEntry = res.result
        }
        this.findPageDataList()
      })
    },
    getCharterTypeList() {
      getDictionaryEntry({ dicType: 'charter_type' }).then(res => {
        if (res.success) {
          this.charterTypeList = res.result
        }
        this.findPageDataList()
      })
    },
    getStoreTypeName(code) {
      if (code) {
        let name = ''
        this.dictionaryEntry.forEach(item => {
          if (item.dicCode == code) {
            name = item.dicName
          }
        })
        return name
      } else {
        return ''
      }
    },
    findPageDataList() {
      let params = { size: this.size, pageIndex: this.current }
      if (this.keywords) {
        params.keywords = this.keywords
      }
      if (this.storeType) {
        params.storeType = this.storeType
      }
      if (this.proId) {
        params.proId = this.proId
      }
      if (this.cityId) {
        params.cityId = this.cityId
      }
      getStoreList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    add() {
      this.titleName = '新增'
      this.addModelShow = true
    },
    del(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          delStore({ id: row.id }).then(res => {
            if (res.success) {
              this.$Message.success('删除成功！')
              this.findPageDataList()
            }
          });
        },
      })
    },
    bind(row) {
      this.currentData = row
      this.appUsermodelShow = true
    },
    setReferrer(row) {
      this.currentData = row
      this.modelShow = true
    },
    delReferrer(row) {
      delStoreParent({ id: row.id, userId: row.parentId }).then(res => {
        if (res.success) {
          this.$Message.success('删除商家推荐人成功！')
          this.findPageDataList()
        }
      });
    },
    checkCombo(row) {
      this.currentData = row
      this.comboManagementShow = true
    },
    editDetial(row) {
      this.titleName = '修改'
      getDetial({ id: row.id }).then(res => {
        if (res.success) {
          this.currentData = res.result
          this.addModelShow = true
        }
      });
      // this.currentData = JSON.parse(JSON.stringify(row))
      // this.addModelShow = true
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    save() {
      let params = this.$refs.addModel.formData
      // params.storePeriodList.forEach(item => {
      //   item.startTime = item.dateArr[0]
      //   item.endTime = item.dateArr[1]
      // })
      this.$refs.addModel.$refs.formInline.validate((valid) => {
        if (valid) {
          addStore(params).then(res => {
            if (res.success) {
              this.$Message.success('保存成功！')
              this.current = 1
              this.findPageDataList()
              this.addModelShow = false
              sessionStorage.setItem('formData', '');
            }
          })
        }
      })
    },
    saveDraft() {
      let formData = this.$refs.addModel.formData
      // formData.storePeriodList.forEach(item => {
      //   item.startTime = item.dateArr[0]
      //   item.endTime = item.dateArr[1]
      // })
      sessionStorage.setItem('formData', JSON.stringify(formData));
      this.addModelShow = false
      this.$Message.success('保存草稿成功！')
    },
    saveFerrer() {
      if (this.$refs.userSelect.currentData.id) {
        let params = {
          id: this.currentData.id,
          parentId: this.$refs.userSelect.currentData.id
        }
        setStoreParent(params).then(res => {
          if (res.success) {
            this.$Message.success('设置商家推荐人成功！')
            this.modelShow = false
            this.findPageDataList()
          }
        });

      } else {
        this.$Message.warning('请选择推荐用户！')
      }
    },
    bindAppUser() {
      if (this.$refs.appUserSelect.currentData.id) {
        let params = {
          storeId: this.currentData.id,
          laikeUserId: this.$refs.appUserSelect.currentData.id
        }
        bindStoreUser(params).then(res => {
          if (res.success) {
            this.$Message.success('绑定商家app用户成功！')
            this.appUsermodelShow = false
            this.findPageDataList()
          }
        });

      } else {
        this.$Message.warning('请选择商家app用户！')
      }
    },
    selectPro(id) {
      this.cityId = ''
      if (id) {
        this.cityList.forEach(item => {
          if (id == item.proId) {
            this.cityChildList = item.cityList || []
          }
        })

      } else {
        this.cityChildList = []
      }
    },
    generateQRCode(row) {
      const content = row.qrCode;
      if (!content) {
        return
      }
      // 生成二维码
      const qrCode = QRCode(0, 'L');
      qrCode.addData(content);
      qrCode.make();

      // 计算二维码模块大小和总尺寸
      const moduleCount = qrCode.getModuleCount();
      const moduleSize = 10; // 每个二维码模块的大小
      const margin = 4; // 二维码到 Canvas 边缘的空白间距

      // 计算 Canvas 尺寸
      const canvasSize = moduleCount * moduleSize + 2 * margin;

      // 生成二维码的 canvas
      const canvas = document.createElement('canvas');
      canvas.width = canvasSize;
      canvas.height = canvasSize;
      const ctx = canvas.getContext('2d');

      // 绘制二维码到 canvas
      for (let y = 0; y < moduleCount; y++) {
        for (let x = 0; x < moduleCount; x++) {
          ctx.fillStyle = qrCode.isDark(y, x) ? '#000' : '#fff';
          ctx.fillRect(
            x * moduleSize + margin,
            y * moduleSize + margin,
            moduleSize,
            moduleSize
          );
        }
      }

      // 将 canvas 转换为 data URL
      this.qrCodeDataUrl = canvas.toDataURL('image/png');
      this.downloadQRCode(row)
    },
    downloadQRCode(row) {
      // 创建一个临时的链接元素
      const link = document.createElement('a');
      link.href = this.qrCodeDataUrl;
      link.download = row.storeName + row.id; // 下载文件名为 qrcode.png

      // 将链接元素添加到文档中
      document.body.appendChild(link);

      // 触发点击链接进行下载
      link.click();

      // 删除添加的链接元素
      document.body.removeChild(link);
    },
  }
}

</script>
<style lang='scss' scoped>
.businessManagement {
  .left-search {
    width: calc(100% - 100px);
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    // width: 120px;
    margin-left: 10px;
    padding-right: 15px;
    text-align: right;
  }
}
.packageManagement {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  z-index: 100;
}
</style>